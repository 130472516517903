html {
  overflow-x: hidden;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "Poppins", sans-serif;
  overflow-x: "hidden";
}

head {
  margin: 0;
}



